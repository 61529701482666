<script>
import get from 'lodash/get';
import invert from 'lodash/invert';
import { sentenceCase } from '@emobg/web-utils';
import {
  BOOKING_TYPE_BADGES,
  BOOKING_TYPES,
} from '@domains/Carsharing/const/bookingTypes';
export default {
  name: 'BookingTypeBadge',
  props: {
    type: {
      type: String,
      default: BOOKING_TYPES.carsharing,
    },
    isAutomatic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bookingType() {
      const bookingTypesInverted = invert(BOOKING_TYPES);
      return get(bookingTypesInverted, this.type, 'carsharing');
    },
  },
  created() {
    this.BOOKING_TYPES_BADGES = BOOKING_TYPE_BADGES;
    this.sentenceCase = sentenceCase;
  },
  methods: {
    get,
  },
};
</script>
<template>
  <div class="BookingTypeBadge d-inline-flex">
    <ui-badge
      :text="sentenceCase(bookingType)"
      v-bind="{
        ...get(BOOKING_TYPES_BADGES, bookingType, {}),
        ...$attrs,
      }"
      data-test-id="booking_type-badge"
    />
    <ui-badge
      v-if="isAutomatic"
      text="Automatic"
      class="ml-1"
      v-bind="{
        ...BOOKING_TYPES_BADGES.unavailability,
        ...$attrs,
      }"
      data-test-id="automatic_booking-badge"
    />
  </div>
</template>
