var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "BookingTypeBadge d-inline-flex" },
    [
      _c(
        "ui-badge",
        _vm._b(
          {
            attrs: {
              text: _vm.sentenceCase(_vm.bookingType),
              "data-test-id": "booking_type-badge",
            },
          },
          "ui-badge",
          {
            ..._vm.get(_vm.BOOKING_TYPES_BADGES, _vm.bookingType, {}),
            ..._vm.$attrs,
          },
          false
        )
      ),
      _vm.isAutomatic
        ? _c(
            "ui-badge",
            _vm._b(
              {
                staticClass: "ml-1",
                attrs: {
                  text: "Automatic",
                  "data-test-id": "automatic_booking-badge",
                },
              },
              "ui-badge",
              {
                ..._vm.BOOKING_TYPES_BADGES.unavailability,
                ..._vm.$attrs,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }